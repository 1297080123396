@import "~bootstrap/scss/bootstrap";

html, body, .main-container, #app {
  height: 100%;
}

.completed-badge {
  background-color: #FF4374;
}
.in-progress-badge {
  background-color: #FFF26F;
}
.waiting-badge {
  background-color: #36C1FF;
}
.on-hold-badge {
  background-color: #E88B31;
}